export class ModalDialog
{
	public getIsOpen(): boolean
	{
		return this.mBackground != null;
	}

	/**
	 * Constructor
	 * 
	 * @param id The DOM identifier which will be assigned to the popup frame
	 */
	public constructor(id: string)
	{
		this.mId = id;
	}

	// todo only accept a single htmlelement? encourages callers to provide a container which can setup their layout options
	public TryOpen(createContentCallback: () => HTMLElement|HTMLElement[]): boolean
	{
		if (ModalDialog.msIsModalOpen)
		{
			console.warn("Cannot open modal dialog - another modal dialog is already open");
			return false;
		}

		this.mBackground = <HTMLDivElement>document.createElement('div');
		this.mBackground.style.position = 'fixed';
		this.mBackground.style.top = '0';
		this.mBackground.style.left = '0';
		this.mBackground.style.bottom = '0';
		this.mBackground.style.right = '0';
		this.mBackground.style.backgroundColor = '#00000088';
		this.mBackground.style.zIndex = '3';

		document.body.appendChild(this.mBackground);
		this.mBackground.addEventListener('click', _ => this.Close());

		this.mPopup = <HTMLDivElement>document.createElement('div');
		this.mPopup.classList.add('popup-dialog');
		this.mPopup.id = this.mId;

		// Note: This is explicitly NOT a child of mBackground to avoid inheriting its click handler
		document.body.appendChild(this.mPopup);

		var content = createContentCallback();
		if (content instanceof Array)
		{
			content.forEach(e => {
				if (e instanceof HTMLElement)
					this.mPopup?.appendChild(e);
			});
		}
		else
		{
			this.mPopup.appendChild(content);
		}

		return true;
	}

	public Close(): void
	{
		if (this.mBackground != null)
		{
			document.body.removeChild(this.mBackground);
			this.mBackground = null;
		}

		if (this.mPopup != null)
		{
			document.body.removeChild(this.mPopup);
			this.mPopup = null;
		}
	}

	private static msIsModalOpen: boolean = false;

	private mBackground: HTMLDivElement | null = null;

	private mPopup: HTMLDivElement | null = null;

	private readonly mId: string;
}
