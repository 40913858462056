import { ModalDialog } from "../../javascript/helpers/ModalDialog";
import { Markdown } from "../../javascript/helpers/Markdown";
import * as $ from "jquery";
import { library as faLibrary, icon as faIcon, dom as faDom } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faArrowRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons"

var gContactUsDialog: ModalDialog = new ModalDialog("popup-contactus");
var gPrivacyPolicyDialog: ModalDialog = new ModalDialog("popup-privacypolicy");

window.addEventListener('load', function()
{
    // Initialise FontAwesome
    faLibrary.add(faEnvelope, faArrowRight, faInfoCircle);

    // Prepare contact us form button
    $('#contact-us-button')?.click(OpenContactUsForm);
    $('#privacy-policy-button')?.click(OpenPrivacyPolicy);

    faDom.watch();
});

function OpenContactUsForm(): void
{
    gContactUsDialog.TryOpen(CreateContactUsForm);
}

function OpenPrivacyPolicy(): void
{
    gPrivacyPolicyDialog.TryOpen(CreatePrivacyPolicyDialog);
}

function CreatePrivacyPolicyDialog(): HTMLElement[]
{
    // Title bar for the form
    var titlebar = document.createElement('div');
    titlebar.classList.add('accentPanel');
    $(titlebar).css({'display': 'flex', 'align-items': 'center'});

    const infoIcon = faIcon(faInfoCircle, {
        styles: { 'color': 'white', 'margin': '0 0.5em 0 0', 'font-size': '2em' }
    }).node[0];

    var heading = document.createElement("h3");
    $(heading)
        .css({'margin': '0px'})
        .text('Privacy Policy');

    titlebar.append(infoIcon, heading);

    // Main body of the policy
    var policyContainer = document.createElement('div');
    policyContainer.innerHTML = "<p>Loading...</p>";

    $.ajax('policies/privacy.md')
        .done(data =>{
            policyContainer.innerHTML = Markdown.Parse(data);
        });

    return [titlebar, policyContainer];
}

function CreateContactUsForm(): HTMLElement[]
{
    // Title bar for the form
    var titlebar = document.createElement('div');
    titlebar.classList.add('accentPanel');
    $(titlebar).css({'display': 'flex', 'align-items': 'center'});

    const envelopeIcon = faIcon(faEnvelope, {
        styles: { 'color': 'white', 'margin': '0 0.5em 0 0', 'font-size': '2em' }
    }).node[0];

    var heading = document.createElement("h3");
    $(heading)
        .css({'margin': '0px'})
        .text('Contact Us');

    titlebar.append(envelopeIcon, heading);

    // Main body of the form
    var formContainer = document.createElement('div');

    var form = document.createElement("form");
    $(form).css({
        'position': 'relative',
        'height': '90vw',
        'max-height': '20em',
        'width': '90vw',
        'max-width': '40em',
        'overflow': 'hidden'});

    // Page 1 - Introduction
    var page1 = document.createElement('div');
    page1.id = "contactus-form-page1";
    $(page1).css({'position': 'absolute', 'transition': '1s', 'width': '100%'});

    var intro = document.createElement("p");
    intro.textContent = "Thank you for your interest in contacting the North East Private Landlords Association (NEPLA)";

    var intro2 = document.createElement("p");
    intro2.textContent = "This contact form is intended for queries about the association and our website. We cannot arrange viewings, provide extra information about or send you a list of properties. Please view our properties available to let on LetsLet and contact landlords directly from the property listings";

    var intro3 = document.createElement("p");
    intro3.textContent = "Click continue to contact NEPLA with your query";

    page1.append(intro, intro2, intro3);



    // Page 2 - Sender Details
    var page2 = document.createElement('div');
    page2.id = "contactus-form-page2";
    $(page2).css({'position': 'absolute', 'transition': '1s', 'transform': 'translateX(100%)', 'width': '100%'});

    var page2Leader = document.createElement('p');
    page2Leader.textContent = "Please fill in your details so we can reply to your message";

    var nameLabel = document.createElement('label');
    nameLabel.htmlFor = 'sender_name';
    $(nameLabel).text('Your Name');

    var nameField = document.createElement('input');
    nameField.type = 'text';
    nameField.required = true;
    nameField.name = 'sender_name';
    nameField.id = 'contactus-form-sender-name';

    var emailLabel = document.createElement('label');
    emailLabel.htmlFor = 'sender_email';
    $(emailLabel).text('Your Email Address');

    var emailField = document.createElement('input');
    emailField.type = 'email';
    emailField.required = true;
    emailField.name = 'sender_email';
    emailField.id = 'contactus-form-sender-email';

    var emailConfirmLabel = document.createElement('label');
    emailConfirmLabel.htmlFor = 'sender_email_confirm';
    $(emailConfirmLabel).text('Confirm your Email Address');

    var emailConfirmField = document.createElement('input');
    emailConfirmField.type = 'email';
    emailConfirmField.required = true;
    emailConfirmField.name = 'sender_email_confirm';
    emailConfirmField.id = 'contactus-form-sender-email-confirm';

    $([nameLabel, emailLabel, emailConfirmLabel])
        .css(
        {
            'display': 'block',
            'color': 'rgb(40, 156, 167)',
            'font-weight': '600'
        });

    $([nameField, emailField, emailConfirmField])
        .css(
        {
            'margin-top': '0.4em',
            'margin-bottom': '1em'
        })
        .keyup(_ => ValidatePage2(form));

    var page2footer = document.createElement('p');
    $(page2footer)
        .css({'font-style': 'italic', 'font-size': '0.8em'})
        .text('Your personal details will be exclusively used to respond to your query. We will not store your details or process them for any other purpose');

    page2.append(page2Leader,
        nameLabel, nameField,
        emailLabel, emailField,
        emailConfirmLabel, emailConfirmField,
        page2footer);



    // Page 3 - Actual message body
    var page3 = document.createElement('div');
    page3.id = "contactus-form-page3";
    $(page3).css({'position': 'absolute', 'transition': '1s', 'transform': 'translateX(100%)', 'width': '100%'});

    var page3Leader = document.createElement('p');
    page3Leader.textContent = "Enter your message below and, once you're finished, click 'Send Message'";

    var titleLabel = document.createElement('label');
    titleLabel.htmlFor = 'message_title';
    $(titleLabel).text('Message Title');

    var titleField = document.createElement('input');
    titleField.type = 'text';
    titleField.required = true;
    titleField.name = 'message_title';
    titleField.id = 'contactus-form-message-title';

    var messageLabel = document.createElement('label');
    messageLabel.htmlFor = 'message_content';
    $(messageLabel).text('Your Message');

    var messageField = document.createElement('textarea');
    messageField.required = true;
    messageField.name = 'message_content';
    messageField.id = 'contactus-form-message-content';

    $([titleLabel, messageLabel])
        .css(
        {
            'display': 'block',
            'color': 'rgb(40, 156, 167)',
            'font-weight': '600'
        });

    $([titleField, messageField])
        .css(
        {
            'width': '90%',
            'margin-top': '0.4em',
            'margin-bottom': '1em'
        });

    $(messageField).css({'height': '10em'});

    page3.append(page3Leader,
        titleLabel, titleField,
        messageLabel, messageField);

    var recaptcha = document.createElement("div");

    grecaptcha.render(recaptcha, {
        'sitekey': '6Le158UUAAAAAIF-dIL1apcLb7lKRhkRlqBPv3i0',
        'size': 'invisible',
        'callback': _ => SubmitContactUsFormContent(form)
    })

    form.append(page1, page2, page3, recaptcha);

    var continueButton = document.createElement("button");
    continueButton.type = 'button';
    continueButton.id = 'contactus-form-continue';
    continueButton.textContent = "Send Message";
    $(continueButton)
        .css({'display': 'block', 'margin': '0 auto', 'font-size': '1.2em', 'padding': '2 22 2 2'})
        .click(_ => ContactUsAdvanceToPage2(form))
        .text('Continue');

    const arrowIcon = faIcon(faArrowRight, {
        styles: { 'color': '#222222', 'margin': '0 0 0 0.5em' }
    }).node[0];
    continueButton.append(arrowIcon);

    formContainer.append(form, continueButton);

    return [titlebar, formContainer];
}

function ContactUsAdvanceToPage2(form: HTMLFormElement)
{
    $('#contactus-form-page1').css('transform', 'translateX(-100%)');
    $('#contactus-form-page2').css('transform', 'translateX(0)');

    $('#contactus-form-continue')
        .off('click')
        .click(_ => ContactUsAdvanceToPage3(form))
        .prop('disabled', !ValidatePage2(form));
}

function ContactUsAdvanceToPage3(form: HTMLFormElement)
{
    if (!ValidatePage2(form, true))
    {
        // Change keyup event to update highlight state
        $(['contactus-form-sender-name', 'contactus-form-sender-email', 'contactus-form-sender-email-confirm'])
            .off('keyup')
            .keyup(_ => ValidatePage2(form, true));
        return;
    }

    $('#contactus-form-page2').css('transform', 'translateX(-100%)');
    $('#contactus-form-page3').css('transform', 'translateX(0)');

    $('#contactus-form-continue')
        .off('click')
        .click(_ => ValidateThenSubmitContactUsForm(form))
        .prop('disabled', !ValidatePage3(form))
        .text('Send Message');
}

function ValidatePage2(form: HTMLFormElement, highlightErrors: boolean = false) : boolean
{
    var name = (<HTMLInputElement>document.getElementById("contactus-form-sender-name"));
    var email = (<HTMLInputElement>document.getElementById("contactus-form-sender-email"));
    var emailConfirm = (<HTMLInputElement>document.getElementById("contactus-form-sender-email-confirm"));

    var nameValid: boolean = ValidateTextInput(name.value, 5);
    var emailValid: boolean = ValidateTextInput(email.value, 5) && email.value.indexOf('@') > 0;
    var emailConfirmValid: boolean = email.value === emailConfirm.value

    var result: boolean = nameValid && emailValid && emailConfirmValid;
    $('#contactus-form-continue').prop('disabled', !result);

    $(name).css('background', !nameValid && highlightErrors ? 'lightcoral' : '');
    $(email).css('background', !emailValid && highlightErrors ? 'lightcoral' : '');
    $(emailConfirm).css('background', !emailConfirmValid && highlightErrors ? 'lightcoral' : '');

    return result;
}

function ValidateTextInput(value: string, minLength: number = 1, maxLength: number = 0)
{
    return value.length >= minLength && (value.length <= maxLength || maxLength == 0);
}

function ValidatePage3(form: HTMLFormElement, highlightErrors: boolean = false) : boolean
{
    return true; // todo validate title & body of message
}

function ValidateContactUsForm(form: HTMLFormElement, displayAlert: boolean = false) : boolean
{
    return ValidatePage2(form, displayAlert)
        && ValidatePage3(form, displayAlert);
}

function ValidateThenSubmitContactUsForm(form: HTMLFormElement): void
{
    if (!ValidateContactUsForm(form, true))
    {
        return;
    }

    grecaptcha.execute();
}

async function SubmitContactUsFormContent(form: HTMLFormElement): Promise<void>
{
    try
    {
        var response = await $.ajax({
            type: "POST",
            url: 'contact/submit.php',
            data: $(form).serialize()
        });

        var result = JSON.parse(response);

        if (result['success'] === true)
            gContactUsDialog.Close();
        else
        {
            alert('Failed to send');
            grecaptcha.reset();
        }
    }
    catch(e)
    {
        alert('Failed to send');
        grecaptcha.reset();
        console.error("Error occurred submitting form", e);
    }
}
